import React from 'react';
import { useTranslation } from 'react-i18next';

import { CUSTOMER_SERVICE_PHONE_NUMBER } from '../../constants';
import deskHelpIcon from '../../assets/purchase/questions.svg';
import './QuestionsInfo.css';
import { usePartnersData } from '../../partners/usePartnersData';

const QuestionsInfo = ({ isPurchase = false }) => {
  const { t } = useTranslation('quoting');
  const { data, isCostco, isPartner } = usePartnersData();

  let title = t('questionsSection.questions');
  let supportPhoneNumber = `${CUSTOMER_SERVICE_PHONE_NUMBER}.`;
  let supportDesc = t('questionsSection.supportDesc');
  let supportSchedule = t('questionsSection.supportSchedule');

  if (isPartner && data.SupportPhoneNumber) {
    supportPhoneNumber = data.SupportPhoneNumber;
  }

  if (isCostco) {
    title = t('questionsSection.vipSupport');
    supportPhoneNumber = data.SupportPhoneNumber;
    supportDesc = data.SupportLabel;
    supportSchedule = data.SupportSchedule;
  }

  return (
    <div className={'Questions-sidebar-container Questions-innerent'
      + `${isPurchase ? ' Questions-purchase' : ' '}`}
    >
      <div className="Questions-sidebar-header">
        <img
          alt=""
          className="Questions-sidebar-help-desk-icon"
          src={deskHelpIcon}
        />

        <h2
          className={`${isPurchase ? 'Questions-sidebar-title-purchase'
            : 'Questions-sidebar-title '}`}
        >
          {title}
        </h2>
      </div>

      <div className="Question-sidebar-text-mobile">
        <p className="Questions-sidebar-questions-text">
          {supportDesc} {supportPhoneNumber}
        </p>

        <p className={'Questions-sidebar-questions-text '
          + 'Questions-sidebar-questions-text-subtitle'}
        >
          {supportSchedule}
        </p>
      </div>
    </div>
  );
};

export { QuestionsInfo };
