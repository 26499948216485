import React from 'react';
import { useTranslation } from 'react-i18next';

import './VipSupportBanner.css';
import { usePartnersData } from '../../../partners/usePartnersData';

const VipSupportBanner = () => {
  const { t } = useTranslation('landing');
  const { data, isCostco, isPartner } = usePartnersData();
  const { SupportLabel, SupportPhoneNumber, SupportSchedule } = data;

  const title = isCostco ? 'title' : 'titleGoodDog';

  if (!isPartner) {
    return null;
  }

  return (
    <div className="Vip-support-main-container">
      <h2 className="Vip-support-title">{t(`vipSupportBanner.${title}`)}</h2>

      <span className="Vip-support-description">
        {`${SupportLabel} ${SupportPhoneNumber}`}
      </span>

      <span className="Vip-support-schedule">
        {SupportSchedule}
      </span>
    </div>
  );
};

export { VipSupportBanner };
