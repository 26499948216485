import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  LANDING_URL,
  URL_LOGIN_ACCOUNT,
  URL_PURCHASE_ERROR,
  URL_PURCHASE,
  URL_ROOT_QUOTE,
  URL_YOUR_INFO,
  URL_PURCHASE_BACKEND_ERROR,
} from '../../routes';
import { scrollToElement } from '../../util';
import { useBeaconSalesforceScript } from '../../hooks';

const PageTabTitle = () => {
  const location = useLocation();
  const titlePrefix = 'Figo Pet Insurance |';

  useBeaconSalesforceScript();

  useEffect(() => {
    switch (location.pathname.toLowerCase()) {
      case LANDING_URL:
        document.title = 'Get a Pet Insurance Quote from Figo';
        break;
      case URL_ROOT_QUOTE:
        document.title = `${titlePrefix} Select Plan`;
        window.scrollTo(0, 0); // scroll to top page
        break;
      case URL_YOUR_INFO:
        document.title = `${titlePrefix} Your Info`;
        scrollToElement('stepHeaderSelectPlan');
        break;
      case URL_PURCHASE:
        document.title = `${titlePrefix} Purchase Complete`;
        window.scrollTo(0, 0); // scroll to top page
        break;
      case URL_PURCHASE_ERROR:
      case URL_PURCHASE_BACKEND_ERROR:
        document.title = `${titlePrefix} Purchase Error`;
        break;
      case URL_LOGIN_ACCOUNT:
        document.title = `${titlePrefix} Login account`;
        window.scrollTo(0, 0); // scroll to top page
        break;
      default:
        document.title = `${titlePrefix} Get A Quote`;
        break;
    }
  }, [location.pathname]);

  return null;
};

export { PageTabTitle };
