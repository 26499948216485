import React from 'react';

import figoLogo from '../../assets/landing/figo-logo.svg';
import './LandingHeader.css';
import { usePartnersData } from '../../partners/usePartnersData';

/* eslint-disable react/no-danger */
const LandingHeader = () => {
  const { isCostco, isGoodDog, isPartner, data, isUSAA } = usePartnersData();
  const topDisclaimerHtml = () => ({ __html: data?.TopDisclaimer });

  const partnerLogo = () => {
    if (!isPartner || (data && !data.Logo)) {
      return null;
    }

    return (
      <img
        alt={data?.PartnerDisplayName}
        className={'Landing-partner-logo'
          + ` ${isUSAA ? 'Landing-partner-logo-usaa' : ''}`}
        src={data?.Logo}
      />
    );
  };

  const partnerDisclaimerTextTop = () => {
    if (!isCostco) {
      return null;
    }

    return (
      <div className="Landing-header-top-disclaimer">
        <div dangerouslySetInnerHTML={topDisclaimerHtml()} />

        <div className="Landing-header-top-footer" />
      </div>
    );
  };

  function renderLeftContent() {
    if (isPartner) {
      return (
        <img
          alt="Figo Pet Insurance"
          className={'Landing-logo-image Landing-logo-image-partner'
            + ` ${isUSAA ? 'Gone' : ''}`}
          src={figoLogo}
        />
      );
    }

    return (
      <img
        alt="Figo Pet Insurance"
        className="Landing-logo-image"
        src={figoLogo}
      />
    );
  }

  return (
    <header className="Landing-header-container">
      {partnerDisclaimerTextTop()}

      <div
        className={'Landing-header '
          + `${isPartner ? 'Landing-header-partner ' : ' '}`
          + `${isGoodDog ? 'Landing-header-partner-good-dog' : ''}`
          + `${isUSAA ? 'Landing-header-partner-usaa' : ''}`}
      >
        {renderLeftContent()}

        {partnerLogo()}
      </div>
    </header>
  );
};

export { LandingHeader };
